.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto; /* Permettre le défilement */
}

.popup-content {
  background: var(--container-color);
  padding: 2rem;
  border-radius: 1rem;
  width: 90%;
  max-width: 600px;
  max-height: 90%; /* Limiter la hauteur à 90% de l'écran */
  position: relative;
  text-align: left;
  color: var(--title-color);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Activer le défilement vertical */
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--title-color);
}

.popup-image {
  max-width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.popup-links {
  margin-top: 1rem;
}

.popup-links a {
  display: inline-block;
  margin: 0.5rem 0.5rem 0 0;
  padding: 0.5rem 1rem;
  background-color: var(--body-color);
  color: var(--title-color);
  text-decoration: none;
  border-radius: 0.5rem;
  transition: background 0.3s;
}

.popup-links a:hover {
  background: var(--primary-color-dark);
}
