.portfolio {
  padding: 4rem 2rem;
  background-color: var(--body-color);
  color: var(--text-color);
}

.portfolio__header {
  text-align: center;
  margin-bottom: 3rem;
}

.section__title {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--title-color);
}

.section__subtitle {
  font-size: 1rem;
  color: var(--title-color);
  background-color: var(--container-color);
  margin-bottom: 1rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 20px var(--shadow-color);
}

.section__competenciesTitle {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-bottom: 1rem;
  text-align: center;
}

.portfolio__container {
  display: flex;
  flex-direction: column;
  background-color: var(--container-color);
  align-items: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 20px var(--shadow-color);
}

.competencies__title {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--title-color);
  text-align: center;
}

.competency__navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navigation__arrow {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: var(--title-color);
  transition: color var(--transition-duration);
}

.navigation__arrow:hover {
  color: var(--title-color);
}

.competency__content {
  flex-grow: 1;
  padding: 0 2rem;
  color: var(--text-color);
}

.competency__title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--title-color);
}

.competency__description {
  margin-bottom: 1.5rem;
  line-height: 1.6;
  color: var(--text-color);
}

.portfolio__images {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.portfolio__image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px var(--shadow-color);
  transition: transform var(--transition-duration), box-shadow var(--transition-duration);
}

.portfolio__image:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 10px var(--shadow-color);
}

.ant-collapse {
  background-color: var(--container-color);
}

.ant-collapse-item {
  background-color: var(--container-color);
  border: none;
}

.ant-collapse-header {
  background-color: var(--container-color);
  color: var(--text-color);
  padding: 1rem;
}

.ant-collapse-content {
  background-color: var(--container-color);
}

.ant-collapse-content-box {
  background-color: var(--container-color);
  color: var(--text-color);
  padding: 1rem;
}

.static-bg {
  background-color: var(--container-color);
  color: var(--text-color);
  padding: 1rem;
  border-radius: 0.5rem;
}

.example__title {
  color: var(--text-color);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.example__description {
  background-color: transparent;
  color: var(--text-color);
  margin: 0;
  padding: 0;
  line-height: 1.6;
  border: none;
  box-shadow: none;
}

.example__description-container {
  background-color: var(--container-color);
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0;
  box-shadow: none;
}

.competency-level {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  background-color: var(--body-color);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px var(--shadow-color);
  width: fit-content;
}

.competency-level__title {
  font-size: 1.2rem;
  color: var(--title-color);
  margin-right: 1rem;
}

.competency-level__badge {
  display: inline-block;
  background-color: var(--container-color);
  color: var(--text-color);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

.portfolio__content {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center vertically */
  width: 100%;
  margin-bottom: 2rem;
  min-height: 200px; /* Adjust as needed for centering */
}

.portfolio__left,
.portfolio__right {
  flex: 1;
  padding: 1rem;
}

.portfolio__left {
  border-right: 1px solid var(--text-color);
  text-align: center; /* Center text horizontally */
}

.portfolio__right {
  padding-left: 2rem;
}

.portfolio__container {
  display: flex;
  flex-direction: column;
  background-color: var(--container-color);
  align-items: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 20px var(--shadow-color);
}

.section__subtitle {
  font-size: 1rem;
  color: var(--title-color);
  background-color: var(--container-color);
  margin-bottom: 1rem;
  line-height: 1.6;
  text-align: justify;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 20px var(--shadow-color);
}

.competencies__title {
  font-size: 1.8rem;
  color: var(--title-color);
  margin-bottom: 1rem;
  text-align: center;
}

.company-logo {
  display: block;
  align-items: center;
  max-width: 100%;
  margin-top: 1rem;
  text-align: center;
  border-radius: 3px;
}

.competency__navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ac-button {
  display: inline-block;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: background-color var(--transition-duration);
  margin-left: 10px;
}

.ac-button:hover {
  background-color: var(--button-hover-bg-color);
}

.details-button {
  display: inline-block;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  border-radius: 0.5rem;
  border: 2px solid var(--button-border-color);
  cursor: pointer;
  font-size: 1rem;
  transition: 
    background-color var(--transition-duration),
    border-color var(--transition-duration),
    box-shadow var(--transition-duration),
    transform var(--transition-duration);
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.what-i-learned {
  padding: 2rem;
  background-color: var(--container-color);
  color: var(--text-color);
  border-radius: 0.5rem;
  box-shadow: 0 4px 20px var(--shadow-color);
  margin-top: 2rem;
  width: 100%;
}

.what-i-learned__content {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.skills__title {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
}

.skills__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.skills__list li {
  margin-bottom: 1rem;
}

.skills__list h5 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--title-color);
}

.skills__list p {
  margin: 0;
  line-height: 1.6;
  color: var(--text-color);
}

.details-button:hover {
  background-color: var(--button-hover-bg-color);
  border-color: var(--button-hover-border-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.details-button:active {
  background-color: var(--button-active-bg-color);
  border-color: var(--button-active-border-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(1px);
}

.details-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--button-focus-shadow-color);
}


.details-button:hover {
  background-color: var(--button-hover-bg-color);
}

@media (max-width: 1040px) {
  .section__title {
    font-size: 1.5rem;
  }

  .portfolio__content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .portfolio__left {
    border-right: none;
    border-bottom: 1px solid var(--text-color);
    padding-bottom: 1rem;
  }

  .portfolio__right {
    padding-left: 0;
    padding-top: 1rem;
  }

  .navigation__arrow {
    font-size: 1.5rem;
  }

  .section__subtitle {
    font-size: 1rem;
    max-width: 100%;
    padding: 0 1rem;
  }

  .portfolio__container {
    padding: 1rem;
  }

  .competencies__title {
    font-size: 1.5rem;
  }

  .competency__navigation {
    flex-direction: column;
    align-items: center;
  }

  .competency__content {
    padding: 0 1rem;
    text-align: left;
  }

  .competency__title {
    font-size: 1.2rem;
  }

  .portfolio__images {
    flex-direction: column;
    align-items: center;
  }

  .portfolio__image {
    width: 100px;
    height: 100px;
  }

  .company-logo {
    max-width: 100%;
    margin-top: 1rem;
  }

  .what-i-learned__content {
    flex-direction: column;
    align-items: center;
  }

  .skills__title {
    font-size: 1.2rem;
  }
}
