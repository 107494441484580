.works__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .works__card {
    background: var(--container-color);
    padding: 1.5rem;
    border-radius: 0.5rem;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .works__card:hover {
    transform: translateY(-10px);
  }
  
  .works__img {
    width: 100%;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .works__title {
    font-size: var(--h3-font-size);
    color: var(--title-color);
    text-align: center;
  }
  