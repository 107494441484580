.footer {
    background-color: var(--container-color);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer__container {
    padding: 2rem 0 2rem;
}

.footer__social {
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
}

.footer__social-link {
    background-color: var(--title-color);
    color: var(--container-color);
    font-size: 1.125rem;
    padding: 0.4rem;
    border-radius: 0.5rem;
    display: inline-flex;
}

.footer__social-link:hover {
    background-color: var(--title-color-dark);
}

.footer__copyright {
    display: block;
    margin-top: 2rem;
    color: var(--title-color);
    text-align: center;
    font-size: var(--small-font-size);
}

.footer__view-counter {
    position: absolute;
    right: 93%;
    top: 53%;
    transform: translateY(-50%);
    font-size: var(--tiny-font-size);
    color: var(--text-color);
}

.view-counter {
    padding: 0.2rem;
    background-color: var(--container-color);
    border: 1px solid var(--text-color);
    border-radius: 4px;
}

.view-counter__title {
    font-size: var(--tiny-font-size);
    color: var(--title-color);
    font-weight: var(--font-semi-bold);
}

@media screen and (max-width: 992px) {
    .footer__social-link {
        padding: 0.25rem;
        border-radius: 0.25rem;
        font-size: 1rem;
    }

    .footer__container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer__view-counter {
        position: static;
        margin-top: 1rem;
        transform: none;
    }
}