:root {
  --link-underline-color: #000; /* Default to black for light mode */
}

body.dark-mode {
  --link-underline-color: #fff; /* Change to white for dark mode */
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

.logo {
  padding-top: 10px;
  width: 6rem;
}

.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.nav__logo,
.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav__list {
  display: flex;
  column-gap: 2rem;
  list-style: none; /* Remove bullets */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  position: relative;
  transition: color 0.3s;
}

.nav__link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -3px;
  left: 50%;
  background-color: var(--link-underline-color);
  transition: width 0.3s ease, left 0.3s ease;
}

.nav__link:hover::after,
.active-link::after {
  width: 100%;
  left: 0;
}

.active-link,
.nav__link:hover {
  color: var(--title-color);
}

.nav__icon,
.nav__close,
.nav__toggle {
  display: none;
}

.nav__actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.language__select {
  padding: 0.5rem;
  border: none;
  background: var(--container-color);
  color: var(--text-color);
  border-radius: 5px;
  font-size: var(--small-font-size);
  cursor: pointer;
}

.language__select:focus {
  outline: none;
}

.darkModeToggle {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  width: 50px;
  height: 50px;
}

.darkModeToggle .emoji {
  font-size: 1.5rem;
  position: absolute;
  transition: opacity 0.5s;
}

#moon {
  opacity: 0;
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .header {
    top: 0;
    bottom: initial;
  }

  .nav {
    height: var(--header-height);
  }

  .nav__menu {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 1.5rem 1.5rem;
    transition: 0.3s;
  }

  .show-menu {
    top: 0;
  }

  .nav__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    list-style: none; /* Remove bullets */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }

  .nav__icon {
    font-size: 1.2rem;
  }

  .nav__close {
    position: absolute;
    left: 1.3rem;
    top: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
  }

  .nav__close:hover {
    color: var(--title-color-dark);
  }

  .nav__toggle {
    font-size: 1.2rem;
    cursor: pointer;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }

  .nav__list {
    column-gap: 0;
    list-style: none; /* Remove bullets */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }
}
